/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $body = $('body');

        $body.addClass('loaded');


        $('#trigger-overlay').click(function(){
          $('.menu').toggleClass('animate');
          $('.bar').toggleClass('change');
          $('.overlay-menu').toggleClass('open');
          $body.toggleClass('open');
         });

         $(window).resize(function () {
           var width = $(window).width();
           if (width > 767){
            $('.body-sub').removeClass('overlayOpen');
             $('.globalNavMenu').removeClass('overlayOpen');
             $('.menu').removeClass('animate');
             $('.bar').removeClass('change');
             $('.hidden-nav').removeClass('animate');
           }
         });



          $('.videoSlideWrapper').slick({
            'dots': true,
            responsive: [
               {
                 breakpoint: 768,
                 settings: {
                   arrows: false,
                 }
               }
             ]
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'about': {
      init: function() {
        // JavaScript to be fired on the home page

      var $body = $('body'),
       $person = $('.person'),
      //  $personImg = $('.person > img'),
       $close = $('#closer');

         $person.removeClass('active');

         $person.click(function(){
           $person.removeClass('active');
           $(this).addClass('active');
           $body.addClass('open').removeClass('closed');
            $('.bar').addClass('change');
            $close.addClass('active');
         });

         $close.click(function(){
           $('.menu').toggleClass('animate');
           $('.bar').removeClass('change');
            $person.removeClass('active');
            $body.toggleClass('open').addClass('closed');
            $(this).removeClass('active');
         });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


      }
    },
    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the page



      },
      finalize: function() {
        // JavaScript to be fired on the page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery);
